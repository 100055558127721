<template>
  <div class="address-page">
    <headerBar :title="i18n.t('bind.bindCardTitle')"  :showArrow="true" @back="back"></headerBar>
    <div class="flex-column-center bank-type-list">
      <div>支持以下银行</div>
      <div class="flex-center">
        <img :src="bankUrl1" alt="">
        <img :src="bankUrl2" alt="" class="small">
        <img :src="bankUrl3" alt="">
        <img :src="bankUrl4" alt="">
      </div>
    </div>
    <div class="address-form">
      <div class="form-item">
        <div class="label">{{i18n.t('bind.Company')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.Company" :placeholder="i18n.t('bind.placeholder4')">
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{i18n.t('bind.RealName')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.RealName" :placeholder="i18n.t('bind.placeholder5')">
        </div>
      </div>
      <div class="form-item" >
        <div class="label">{{i18n.t('bind.Card')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.Card" :placeholder="i18n.t('bind.placeholder6')">
        </div>
      </div>
      <div class="form-item" >
        <div class="label label-self">{{i18n.t('bind.Type')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.Type" :placeholder="i18n.t('bind.placeholder9')">
        </div>
      </div>
      <div class="form-item" >
        <div class="label label-self" >{{i18n.t('bind.TaxID')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.TaxID" :placeholder="i18n.t('bind.placeholder8')">
        </div>
      </div>
      <div class="form-item" v-show="readonly === false">
        <div class="label">{{i18n.t('bind.label2')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.password" :placeholder="i18n.t('bind.placeholder2')">
        </div>
      </div>
      <div class="btn" >
        <Button @click="submit">{{i18n.t('address.btn')}}</Button>
      </div>
    </div>


  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
import {addBank, addCard, addUsdt, doWithdraw, getBankList, getUsdtList} from "@/api/user";
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      bankUrl1: require('../../assets/imgs/bank1.png'),
      bankUrl2: require('../../assets/imgs/bank2.png'),
      bankUrl3: require('../../assets/imgs/bank3.png'),
      bankUrl4: require('../../assets/imgs/bank4.png'),
      form: {
        Company: '',
        RealName: '',
        TaxID: '',
        Card: '',
        Type: '',
        password: '',
      },
      readonly: false,
      userMoney: '0.00',
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      
    },
    back() {
      this.$router.go(-1)
    },
    setMoney(money) {
      this.form.number =  money
    },
    submit() {
        let form = new FormData()
        form.append('Company', this.form.Company)
        form.append('RealName', this.form.RealName)
        form.append('Card', this.form.Card)
        form.append('TaxID', this.form.TaxID)
        form.append('Type', this.form.Type)
        form.append('password', this.form.password)
      addBank(form).then(res => {
            if (res.code == 0) {
              this.back()
                this.$toast(res.msg)
            } else {
                this.$toast(res.msg)
            }
        })
    },
    setAll() {
      this.form.money = this.userMoney
    }
  }
}
</script>
<style scoped>
.label-self::before{
  content: none !important; /* 或 content: ""; */
}
</style>